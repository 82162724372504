
import './home.scss';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from "../../components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import brandRepass from "../../assets/img/logo-white-noname.png";
import { Helmet } from "react-helmet";

function home() {
    return (
        <div className="home">

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
                <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
                <meta name="author" content="Repass" />
                <title>Repass: Compra e venda de veículos premium e exclusivos</title>
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
                <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
                <meta property="og:url" content="https://repass.com.br/" />
                <meta property="og:site_name" content="Repass" />
                <meta property="og:see_also" content="https://repass.com.br/" />
                <meta property="og:image" content="https://repass.com.br/" />
                <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
                <meta property="og:image:type" content="image/jpg" />
            </Helmet>

            <Header />
            <main>
                <section id="main-banner">
                    <div className="container bg-pd">
                        <h1 className="small mb-90 text-center italic">Carros exclusivos, para clientes exigentes.</h1>
                        <div className="grid col-2 sm-gap">
                            <div>
                                <h1 className="main-title mb-40">Compre ou venda o carro dos sonhos!</h1>
                                <span className="fn-20 mb-20">Repass é forma mais rápida, eficiente e segura de
                                    comprar ou vender o seu carro!</span>
                            </div>
                            <div className="grid ji-gc">
                                <Button className="btn btn-bg btn-red fb-28 as-ge mb-25 h-black" text="Comprar" url="/comprar" />
                                <Button className="btn btn-bg btn-black fb-28 as-gs mt-25" text="Vender" url="/quanto-vale-meu-carro" />
                            </div>
                        </div>
                    </div>
                    <div className="images"></div>
                </section>
                <section id="benefits">
                    <div className="container">
                        <h1 className="fb-50 mb-60">Quais são os benefícios de comprar na Repass?</h1>
                        <ul className="benefits-list grid md-gap fn-20-40">
                            <li className="flex">
                                <img alt="Logo da Repass" src={brandRepass} className="mt-10 mr-40" />
                                Trabalhamos para facilitar sua vida, compre carros sem burocracia ou enrolação.
                            </li>
                            <li className="flex">
                                <img alt="Logo da Repass" src={brandRepass} className="mt-10 mr-40" />
                                Todos os carros são inspecionados rigorosamente por especialistas.
                            </li>
                            <li className="flex">
                                <img alt="Logo da Repass" src={brandRepass} className="mt-10 mr-40" />
                                Todos os carros são entregues revisados, limpos e higienizados.
                            </li>
                            <li className="flex">
                                <img alt="Logo da Repass" src={brandRepass} className="mt-10 mr-40" />
                                Devolução garantida caso não fique satisfeito com o carro.
                            </li>
                        </ul>
                    </div>
                </section>

                <section id="more-benefits" className="container mb-140">
                    <h1 className="fb-50 mb-120">Além destes benefícios, você terá:</h1>
                    <section className="grid col-3 bg-gap">
                        <div className="benefits-card">
                            <FontAwesomeIcon className="icon" icon={['far', 'clock']} />
                            <h4 className="fb-25">Gaste menos tempo</h4>
                            <p className="fn-18">Com a experiência da Repass tudo é feito com mais agilidade, pois sabemos o quanto seu
                                tempo é valioso.</p>
                        </div>
                        <div className="benefits-card">
                            <FontAwesomeIcon className="icon" icon={['far', 'gem']} />
                            <h4 className="fb-25">Altíssima qualidade</h4>
                            <p className="fn-18">Somos especializados em carros premium, são todos veículos exclusivos que impressionam até
                                os clientes mais exigentes.</p>
                        </div>
                        <div className="benefits-card">
                            <FontAwesomeIcon className="icon" icon={['fas', 'user-shield']} />
                            <h4 className="fb-25">Garantia completa por 1 ano</h4>
                            <p className="fn-18">A Repass oferece garantia completa de 1 ano contra defeitos no motor, câmbio, direção, freios
                                e mais.</p>
                        </div>
                    </section>
                </section>
                <section className="banner mb-120" />
                <section id="sell-your-car">
                    <div className="container mb-140">
                        <div className="grid col-12 bg-gap mb-50">
                            <h1 className="fb-50 span-5">Troque de carro com a Repass</h1>
                            <Button className="btn btn-bg btn-red fb-28 mb-25 span-7 w-100 mw-100 as-gc h-black" text="Quanto vale meu carro?" url="/quanto-vale-meu-carro" />
                        </div>
                        <h1 className="fn-50 mb-40">Vender seu carro é mais fácil do que você imagina!</h1>
                        <p className="fn-20 pr-10">
                            A Repass sempre paga um preço justo pelo seu carro usado, o processo é extremamente
                            rápido, confiável, sem burocracia ou enrolação. Além disso, possuímos também troca facilitada
                            caso queira adquirir um de nossos incríveis carros.
                        </p>
                    </div>
                </section>
                <section id="help">
                    <h1 className="fb-50 mb-60">Dúvidas Frequentes</h1>
                    <div className="grid col-2 sm-gap-30 w-50 m-auto">
                        <Button className="button-help btn btn-black fb-23 mobile-mb-25" text="Comprar" url="/precisa-de-ajuda" />
                        <Button className="button-help btn btn-black fb-23" text="Vender" url="/precisa-de-ajuda" />
                        {/* <Button className="button-help btn btn-black fb-23" text="Anunciar" url="/anunciar" /> */}
                    </div>
                </section>
                <section id="sell-it">
                    <div className="sell-grid grid col-2 ai-gc sm-gap m-auto">
                        <h1 className="fb-34 w-100">Pronto para vender seu carro?</h1>
                        <Button className="sell-btn btn btn-red fb-23" text="Quero Anunciar" url="/anunciar" />
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default home;
