import React, {useEffect, useState} from 'react';
import Routes from '../config/routes';
import '../assets/css/app.scss';
import '../config/config.js';
import Cookies from "./Cookies";
import config from "../config/config";
import axios from 'axios';

function App() {
  const TOTALS_URI = `${config.api}/totals?access_token=${config.access_token}`;
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const getData = async() => {
      setLoading(true);
      const totals = await axios.get(`${TOTALS_URI}`);
      config.totals = totals?.data?.response;
      setLoading(false);
    }
    (async function getDataAsync() {
      await getData();
    })();
  }, [TOTALS_URI])
  return (
    <div className="app">
      {loading ? <div className="loader loader-default is-active"/> : <Routes />}
      <Cookies />
    </div>
  );
};

export default App;