import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Card from './Card';

function SearchScroll(props) {

    let state = {
        items: props.items,
    };

    return (
        <div style={{ width: '100%', clean: 'both' }} >
            <InfiniteScroll
                dataLength={state.items.length}
                next={props.fetchMoreData}
                hasMore={props.hasMore}
                loader={(props.loader) ? <div className="loader loader-default is-active" /> : ''}
                style={{ overflowY: 'hidden' }}
            >
                <div className={`grid col-4 col-lg-3 sm-gap-30`}>
                    {state.items.map((vehicle, e) => (
                        <Card key={e} vehicle={vehicle} />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
};

export default SearchScroll;