import React from 'react';
import '../assets/css/buttons.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Button(props) {
    let text = props.text;
    let target = props?.target || '_self';
    if (props.icon) {
        text = <div className="btn-t">
            <FontAwesomeIcon className="icon float-left" icon={props.icon} />
            <div className="float-left va-m">{props.text}</div>
        </div>;
    }
    return (
        <a href={props.url} target={target} onClick={props.onClickButton} alt={props.text} className={`button ${ props.className }`}>{ text }</a>
    );
};

export default Button;