
import './aboutUs.scss';
import Header from "../../components/Header";
import React from "react";
import Footer from "../../components/Footer";
import imgAbout from "../../assets/img/about-us.png";
import brandRepass from "../../assets/img/logo-red.png";
import { Helmet } from "react-helmet";

function aboutUs() {
  return (
    <div className="about-us">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
        <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
        <meta name="author" content="Repass" />
        <title>Repass: Sobre nós</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:url" content="https://repass.com.br/" />
        <meta property="og:site_name" content="Repass" />
        <meta property="og:see_also" content="https://repass.com.br/" />
        <meta property="og:image" content="https://repass.com.br/" />
        <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Header />
      <main>
        <section className="mt-100 mb-40" id="about-us">
          <div className="container large">
            <div className="group-top-about-us text-right">
              <h1 className="mb-40">Sobre <br /> Nós</h1>
              <p className="fn-20">Nascemos para revolucionar o modo com que você compra e vende veículos premium.</p>
            </div>
          </div>
          <div className="images" />
          <p className="container large fn-20 text-center mb-120">Fundada em 2020, a Repass nasceu para atender uma demanda de mercado quase inexistente
          no país: a compra e venda de veículos premium. Possuímos um grande estoque de veículos
          exclusivos, mas não é apenas isso que nos torna referência, o ponto principal é a qualidade doserviço que prestamos à nossos exigentes clientes. Afinal, o brasileiro é apaixonado por carros,
                mas também por praticidade.</p>
          <div className="container large grid col-12 id-gap mb-200">
            <div className="span-5">
              <img className="m-auto" src={imgAbout} alt="Sobre Nós" />
            </div>
            <div className="span-7">
              <ul className="benefits-list relative grid id-gap fn-20-40">
                <li className="flex">
                  <img alt="Logo da Repass" src={brandRepass} className="mt-10 mr-20" />
                      Aqui você encontra exatamente o que procura
                    </li>
                <li className="flex">
                  <img alt="Logo da Repass" src={brandRepass} className="mt-10 mr-20" />
                      Trabalhamos para facilitar ao máximo sua compra ou venda
                    </li>
                <li className="flex">
                  <img alt="Logo da Repass" src={brandRepass} className="mt-10 mr-20" />
                      Tenha o carro dos seus sonhos!
                    </li>
              </ul>

            </div>
          </div>
          <div className="container large grid col-4 bg160-gap mb-80 d-none">
            <div>
              <div className="square" />
              <h1 className="fb-25 text-center mb-30">Lorem ipsum dolor sit amet</h1>
              <p className="fn-20 text-center">Morbi pellentesque elit ac urna volutpat, at ultricies nunc fringilla. Donec ligula sem, lacinia
                    vitae aliquet id, imperdiet nec ligula. Praesent luctus tellus molestie condimentum malesuada</p>
            </div>
            <div>
              <div className="square" />
              <h1 className="fb-25 text-center mb-30">Lorem ipsum dolor sit amet</h1>
              <p className="fn-20 text-center">Morbi pellentesque elit ac urna volutpat, at ultricies nunc fringilla. Donec ligula sem, lacinia
                    vitae aliquet id, imperdiet nec ligula. Praesent luctus tellus molestie condimentum malesuada</p>
            </div>
            <div>
              <div className="square" />
              <h1 className="fb-25 text-center mb-30">Lorem ipsum dolor sit amet</h1>
              <p className="fn-20 text-center">Morbi pellentesque elit ac urna volutpat, at ultricies nunc fringilla. Donec ligula sem, lacinia
                    vitae aliquet id, imperdiet nec ligula. Praesent luctus tellus molestie condimentum malesuada</p>
            </div>
            <div>
              <div className="square" />
              <h1 className="fb-25 text-center mb-30">Lorem ipsum dolor sit amet</h1>
              <p className="fn-20 text-center">Morbi pellentesque elit ac urna volutpat, at ultricies nunc fringilla. Donec ligula sem, lacinia
                    vitae aliquet id, imperdiet nec ligula. Praesent luctus tellus molestie condimentum malesuada</p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default aboutUs;
