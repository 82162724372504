import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const divStyle = {
    position: 'fixed',
    width: '60px',
    height: '60px',
    bottom: '40px',
    right: '40px',
    backgroundColor: '#25d366',
    color: '#FFF',
    borderRadius: '50px',
    textAlign: 'center',
    fontSize: '30px',
    boxShadow: '2px 2px 3px #999',
    zIndex: '100'
};

const whatsFloat = {
    marginTop: '16px'
};

export default function Whatsapp() {
    return (

        <a href="https://api.whatsapp.com/send?phone=5547999465058&text=Olá%20Repass,%20poderia%20me%20ajudar?" style={divStyle} target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="fa fa-whatsapp icon" style={whatsFloat} icon={['fab', 'whatsapp']} />
        </a>
    );
};