
import './termsUse.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React from "react";

function termsUse() {
    return (
        <div className="terms-use">
            <Header />
            <main className="container large">
              <h1 className="title">Termos de uso</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
              <h3>Lorem ipsum</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
              <h3>Lorem ipsum</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
              <h3>Lorem ipsum</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
              <h3>Lorem ipsum</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
              <h3>Lorem ipsum</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
              <h3>Lorem ipsum</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
              <h3>Lorem ipsum</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
                Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
                lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
                egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci. Nam
                hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris imperdiet
                ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <p>Morbi pellentesque elit ac urna volutpat,
                at ultricies nunc fringilla. Donec ligula sem, lacinia vitae aliquet id, imperdiet nec ligula. Praesent
                luctus tellus molestie condimentum malesuada. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Morbi at libero tellus. In nulla risus, egestas in leo eu,
                semper pulvinar eros. Sed scelerisque interdum pharetra. </p>
            </main>
            <Footer />
        </div>
    );
}

export default termsUse;
