import React, { useState } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../assets/css/search.scss';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Search(props) {
  /* eslint-disable */

  const history = useHistory();
  const defaultInputValue = props?.defaultInputValue || '';

  const [text, setText] = useState(defaultInputValue);
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (text) {
      localStorage.setItem('searchText', text);
    } else {
      localStorage.setItem('searchText', '');
    }

    history.push(`/comprar`);
    window.location.reload();
  };
  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(event)
    }
  };

  return (
    <form className="search-form w900">
      <input type="text" placeholder="Busque seu carro exclusivo, aqui" value={text} onKeyDown={handleEnter} onChange={(e) => setText(e.target.value)} style={{ width: '100%' }} />
      <FontAwesomeIcon className="icon" icon={['fas', 'search']} onClick={handleSubmit} />
    </form>
  );
};

export default Search;