
import ReactModal from 'react-modal';
import React, { useState, useEffect } from "react";
import Button from "./Buttons";
import config from "../config/config";
import axios from 'axios';
import '../assets/css/modaloffer.scss';
import { Alert } from 'reactstrap';

function ModalOffer(props) {
    const modaltitle = props.title;

    const MARKS_URI = `${config.api}/marks?access_token=${config.access_token}`;
    const EXCHANGE_URI = `${config.api}/exchange?access_token=${config.access_token}`;
    const [etapa, setEtapa] = useState(1);
    // Form Content
    const [form, setForm] = useState({
        name: '',
        phone: '',
        mark: '',
        model: '',
        year_model: '',
        plate: '',
        km: '',
        cpf: ''
    });
    const [marks, setMarks] = useState([]);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        target.classList.remove('danger-border');
        let isValid = true;
        if (['km', 'year_model'].includes(name)) {
            const numbers = /^[0-9]+$/;
            if (value && !value.match(numbers)) isValid = false;
        }
        if (isValid) setForm({ ...form, ...{ [name]: value } });
    }

    const checkMaskPhone = (event) => {
        // const phone = document.getElementById('phone');
        event.target.classList.remove('danger-border')
        let valor = event.target.value;
        valor = valor.replace(/\D/g, "");
        valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
        valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
        setForm({ ...form, ...{ phone: valor } });
    }

    const onBlurPhone = (event) => {
        if (form.phone.length < 15) {
            setForm({ ...form, ...{ phone: '' } });
        }
    }

    function checkValidation(dto) {
        let retorno = true, campo;
        for (let i in dto) {
            if (config.isEmptyNullOrUndefined(dto[i])) {
                campo = document.getElementById(i);
                campo.classList.add('danger-border');
                retorno = false;
            }
        }

        return retorno;
    }

    async function submitForm(event) {
        event.preventDefault();
        if (etapa === 1) {
            let isValid = checkValidation({ name: form.name, phone: form.phone });
            if (isValid) setEtapa(2);
        } else {
            let isValid = true;/* checkValidation({ mark: form.mark, model: form.model, year_model: form.year_model, plate: form.plate, km: form.km }); */
            if (isValid) {
                let body = new FormData();
                body.append('name', form.name);
                body.append('phone', (form.phone).replace(/\D/g, ""));
               // const mark = marks.find(m => m.id === form.mark);
                body.append('mark', (form.mark ? form.mark : 'Não informado'));
                body.append('model', (form.model ? form.model : 'Não informado'));
                body.append('year_model', (form.year_model ? form.year_model : '0000'));
                body.append('plate', (form.plate ? form.plate : 'NAO0000'));
                body.append('km', (form.km ? form.km : 'Não informado'));
                body.append('cpf', (form.cpf ? form.cpf : 'Não deseja financiar'));
                let post = {
                    method: 'post',
                    url: `${EXCHANGE_URI}`,
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    data: body
                }
                const res = await axios(post)
                if (res.data?.response?.status) {
                    setshowAlert(true);
                    initData();
                }
            }
        }
    }

    function initData() {
        const defaultForm = {
            name: '',
            phone: '',
            mark: '',
            model: '',
            year_model: '',
            plate: '',
            km: '',
            cpf: ''
        }
        setForm(defaultForm);
    }

    useEffect(() => {
        (async function getDataAsync() {
            const getMarks = await axios.get(`${MARKS_URI}`);
            setMarks(getMarks.data?.response);
        })();
    });

    const [showAlert, setshowAlert] = useState(false);

    return (
        <ReactModal isOpen={props.show} className="modal-offer">

            <div className="text-center" style={{ paddingBottom: '40px' }}>
                <span>{modaltitle}</span>

                <a href="https://repass.com.br/" className="flex ai-fc btn-exit col-3" onClick={props.hideModal}>Fechar</a>
            </div>

            <form className="mb-100 mobile-form">
                <div className="id-gap"> {/* grid col-2 */}
                    {/* <div className="span-2 grid col-4 lt-gap">
                        <a className={`button btn ${etapa === 1 ? 'btn-red h-white' : 'btn-white c-black'} fb-20 w-100 mw-100 as-gc`}> Etapa 1 </a>
                        <a className={`button btn ${etapa === 2 ? 'btn-red h-white' : 'btn-white c-black'} fb-20 w-100 mw-100 as-gc`} onClick={submitForm}> Etapa 2 </a>
                    </div> */}
                    {etapa === 1 ? (<div className="grid id-gap">
                        <input className="fn-20" type="text" placeholder="Seu nome*" name="name" id="name" required="" value={form.name} onChange={handleInputChange} />
                        <input className="fn-20" type="phone" placeholder="Whatsapp*" name="phone" id="phone" required="" maxLength="15" pattern="\(\d{2}\)\s*\d{5}-\d{4}"
                            value={form.phone} onChange={checkMaskPhone} onKeyPress={checkMaskPhone} onBlur={onBlurPhone} />
                        <span className="fn-20" style={{ textAlign: 'center', verticalAlign: 'medium' }}>Pretende financiar?</span>
                        <input className="fn-20" type="text" placeholder="Insira seu CPF" name="cpf" id="cpf" value={form.cpf} onChange={handleInputChange} />
                        <Button className="btn btn-red fb-20 w-100 mw-100 as-gc h-black" text="Avançar" onClickButton={submitForm} />
                    </div>) : (<div className="grid id-gap">
                        {/* <input className="fn-20" type="text" placeholder="Marca*"/> */}
                        <span className="fn-20" style={{ textAlign: 'center', verticalAlign: 'medium' }}>Possui carro na troca?</span>
                        <select name="mark" id="mark" className="select-form-default fn-20" value={form.mark} onChange={handleInputChange}>
                            <option value="" disabled defaultValue hidden>Marca</option>
                            {(marks || []).map((option) => {
                                return (<option key={option?.id || option} value={option?.id || option}>{option?.label || option}</option>)
                            })}
                        </select>
                        <input className="fn-20" type="text" placeholder="Modelo/Versão" name="model" id="model" value={form.model} onChange={handleInputChange} />
                        <input className="fn-20" type="text" placeholder="Quilometragem" name="km" id="km" value={form.km} onChange={handleInputChange} />
                        <input className="fn-20" type="text" placeholder="Placa" name="plate" id="plate" value={form.plate} onChange={handleInputChange} />
                        <input className="fn-20" type="text" placeholder="Ano do modelo" maxLength="4" name="year_model" id="year_model" value={form.year_model} onChange={handleInputChange} />
                        <Button className="btn btn-red fb-20 w-100 mw-100 as-gc h-black" text="Concluir" onClickButton={submitForm} />
                    </div>)}

                    {(showAlert) ? (<Alert color="white" className="beauty-alert">
                        Mensagem enviada com sucesso!
                    </Alert>) : ''}

                </div>
            </form>

        </ReactModal>
    );
};

export default ModalOffer;