import '../assets/css/card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import config from "../config/config";

library.add(far, fas)


function Card(props) {
  const url = (props?.vehicle?.url || '').replace(config.url, '');

  // Favorite
  const [favorite, setFavorite] = useState({});
  const saveFavorite = (event, id) => {
    event.preventDefault();
    const isFavorite = config.addFavorite(id);
    addFavorite(isFavorite);
  }

  const addFavorite = (isFavorite) => {
    setFavorite({
      icon: isFavorite ? ['fas', 'heart'] : ['far', 'heart'],
      text: isFavorite ? 'Favorito' : 'Favoritar',
      favoriteClass: isFavorite ? 'favorite' : ''
    });
  }
  // Favorite
  useEffect(() => {
    // Set favorite
    const isFavorite = config.isFavorite(props.vehicle.id);
    addFavorite(isFavorite);
  }, [props.vehicle.id]);

 /*  const removeFavorite = (event, id) => {
    event.preventDefault();
    config.addFavorite(id);
    window.location.reload();
  } */
  return (
    <div className="vehicle-content relative">
    {/*   {props.canRemove &&
        <div className="remove">
          <FontAwesomeIcon className="icon-remove" icon={['fas', 'times']} onClick={(evt) => removeFavorite(evt, props.vehicle.id)} />
        </div>
      } */}
      <a href={url} target="_blank" rel="noreferrer">
      <div className="img" style={{
        background: `url(${props.vehicle?.imagens?.[1]}) center center / cover no-repeat`,
        backgroundColor: `var(--light-grey-1-o5)`
      }} />
      </a>
      <a href={url} target="_blank" rel="noreferrer" className="grid p-20 block relative">
        <h4 className="fb-18 mb-15">{props.vehicle?.marca + ' - ' + props.vehicle?.modelo}
        <FontAwesomeIcon className="heart" icon={favorite.icon} onClick={(evt) => saveFavorite(evt, props.vehicle.id)} style={{float:'right'}}/>
        </h4>
        <h5 className="fn-16 mb-15" dangerouslySetInnerHTML={{ __html: props.vehicle?.titulo }} />
        <span className="as-ge block mb-15">
          {props.vehicle?.valor >= 1000 ?
            <small className="fn-16 mr-5">R$</small>
            : ''
          }
          {props.vehicle?.valor >= 1000 ?
            <NumberFormat className="fb-25" value={props.vehicle?.valor} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
            :
            <div className="fb-23">Sob Consulta</div>
          }
        </span>
        <span className="as-ge block mb-10">{props.vehicle?.km} km</span>
        <div className="as-ge">
          <span className="block f300-14">{props.vehicle?.ano_fabricacao}/{props.vehicle?.ano_modelo}</span>
          <FontAwesomeIcon className="icon" icon={['fas', 'arrow-right']} />
        </div>
      </a>

    </div>

  );
};

export default Card;