import React, {useEffect} from 'react';
import '../assets/css/header.scss'
import logo from '../assets/img/logo.svg';
import config from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {library} from "@fortawesome/fontawesome-svg-core";
import ReactPixel from 'react-facebook-pixel';
import Whatsapp from './Whatsapp';

ReactPixel.init(5044203362321523);
ReactPixel.pageView();

library.add(far, fab, fas);
function Header() {
  useEffect(() => {
    const header = document.querySelector('header');
    const headerHeight = header.offsetHeight;
    window.addEventListener('scroll', () => {
      if (window.scrollY > headerHeight) {
        header.classList.add('shadow');
      } else {
        header.classList.remove('shadow');
      }
    });
  
    const toggleMenu = document.querySelector('.toggle-menu');
    const closeMenu = document.querySelector('.close-menu');
    const mainNavigation = document.querySelector('.main-navigation');
  
    toggleMenu.addEventListener('click', e => {
      e.preventDefault();
      mainNavigation.classList.add('active');
    })
  
    closeMenu.addEventListener('click', e => {
      e.preventDefault();
      mainNavigation.classList.remove('active');
    });
  
    document.addEventListener('click', e => {
      if (!e.target.closest('.main-navigation') && !e.target.closest('.toggle-menu')) {
        mainNavigation.classList.remove('active');
      }
    });
  })
  
  return (
    <header>
      <Whatsapp/>
      <div className="container large lt-pd flex ai-fc jc-sb">
        <div className="flex ai-fc">
          <a href="/" className="logo">
            <img alt="Repass" src={logo} className="logo" />
          </a>
          <ul className="flex ai-fc social-networks">
            <li>
              <a href={config.socialNetworks.facebook} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </a>
            </li>
            <li>
              <a href={config.socialNetworks.instagram} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
            </li>
          </ul>
        </div>
        <div className="flex ai-fc header-actions fs-20">
          <ul className="flex ai-fc mobile-none mr-40">
            {config.nav.map((nav, i) => {
              return <li key={`nav-${i}`}>
                <a href={nav.url} target={nav.target}>{nav.title}</a>
              </li>
            })}
          </ul>
          <a href={config.url} className="toggle-menu desktop-none ml-20 mr-20">
            <FontAwesomeIcon icon={['fas', 'bars']} />
          </a>
          <ul className="flex ai-fc">
            <li>
              <a href="/seus-carros-favoritos">
                <FontAwesomeIcon className="heart" icon={['far', 'heart']} />
              </a>
            </li>
          </ul>
        </div>
      </div>
  
      <nav className="main-navigation flex fd-fc jc-sb">
        <a href="https://portal.rankim.com/#" className="close-menu">
          <FontAwesomeIcon icon={['fas', 'times']} />
        </a>
        <div className="mt-50">
          <ul className="mb-20 ph-20">
            {config.nav.map((nav, i) => {
              return <li key={`nav-${i}`}>
                <a href={nav.url}>{nav.title}</a>
              </li>
            })}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;