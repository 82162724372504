
import './errors.scss';
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import image404 from '../../assets/img/logo-black-noname.png';

function error404() {
    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
                <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
                <meta name="author" content="Repass" />
                <title>Repass: Apaixone-se por um carro</title>
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
                <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
                <meta property="og:url" content="https://repass.com.br/" />
                <meta property="og:site_name" content="Repass" />
                <meta property="og:see_also" content="https://repass.com.br/" />
                <meta property="og:image" content="https://repass.com.br/" />
                <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
                <meta property="og:image:type" content="image/jpg" />
            </Helmet>

            <Header />
            <div className="error404">
                <img alt="404" src={image404} className="image404" />
                <h2>A página ou conteúdo não existe mais.</h2>
                <a href="/comprar" className="button btn btn-bg btn-red fb-28 as-ge mb-25 h-black">Apaixone-se por um carro</a>
            </div>
            <Footer />
        </div>
    );
}

export default error404;

