
import './search.scss';
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/Search";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import config from '../../config/config';
import 'pure-css-loader/src/loader-default.sass';
import NumberFormat from "react-number-format";
import Button from "../../components/Buttons";
import SearchScroll from '../../components/SearchScroll';
import { isMobile } from "react-device-detect";

function Search() {

    /* eslint-disable */
    const filtersDefault = { ...{ text: localStorage.getItem('searchText'), total_pages: 0, order: 1, page: 1, stock_code: 'VU', mark: '', mark_text: '', model: '', model_text: '', version: '', version_text: '', year_min: '', year_max: '', value_min: '', value_max: '', km_min: '', km_max: '', optionals: [] } };
    const [filters, setFilters] = useState(filtersDefault);
    const [validation, setValidation] = useState(filtersDefault);
    const [forceMobile, setForceMobile] = useState(false);

    const resetSearch = () => {

        localStorage.setItem('searchText', '');

        setVehiclesList([]);

        vehiclesList = [];

        filters.page = 1;
        filters.total_pages = 0;
        filters.text = '';

        let filterTemp = {};

        Object.keys(filters).forEach(key => {
            if (filters[key]) filterTemp[key] = filters[key];
        })

    }

    const MARKS_URI = `${config.api}/marks?access_token=${config.access_token}&stock_code=VU`;
    const MODELS_URI = `${config.api}/models?access_token=${config.access_token}&stock_code=VU`;
    const VERSIONS_URI = `${config.api}/versions?access_token=${config.access_token}&stock_code=VU`;
    const OPTIONALS_URI = `${config.api}/optionals?access_token=${config.access_token}&stock_code=VU`;

    const scrollTop = function () {
        window.scrollTo(0, 0);
    };

    const handleInputChange = async (event) => {

        if (!isMobile && !forceMobile) {
            resetSearch();
        }

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'mark') {

            filters['mark_text'] = target.options[target.selectedIndex].text;
            filters[name] = value;

            const getModels = await axios.get(`${MODELS_URI}` + '&mark_text=' + filters['mark_text']);

            setOptions({
                ...options, ...{
                    models: [{ id: '', label: 'Todos' }, ...getModels.data?.response]
                }
            });


        } else if (name === 'model') {

            filters['model_text'] = target.options[target.selectedIndex].text;
            filters[name] = value;

            filters['version_text'] = '';
            filters['version'] = '';


            const getVerions = await axios.get(`${VERSIONS_URI}` + '&model_text=' + filters['model_text']);

            setOptions({
                ...options, ...{
                    versions: [{ id: '', label: 'Todas' }, ...getVerions.data?.response]
                }
            });
        } else if (name === 'version') {

            filters['version_text'] = target.options[target.selectedIndex].text;
            filters[name] = value;

        } else {
            filters[name] = value;
        }

        const id = target.id;
        let input = document.getElementById(name);

        if (input) input.classList.remove('danger-border');

        if (name === 'optionals') {
            let optionals = filters[name];
            if (optionals.includes(id)) {
                optionals = optionals.filter(obj => obj !== id);
            } else {
                optionals.push(id)
            }
            setFilters({ ...filters, ...{ [name]: optionals } });
        } else {
            setFilters({ ...filters });
        }

        if (!isMobile && !forceMobile) {
            getSearch();
        }

        scrollTop();
    }

    let [vehiclesList, setVehiclesList] = useState([]);
    const SEARCH_URI = `${config.api}/search?access_token=${config.access_token}&tipo=1`;
    const [loading, setLoading] = useState(true);

    const getSearch = async (e) => {

        setLoading(true);
        e?.preventDefault();

        let filterTemp = filters;
        let query = '';

        Object.keys(filterTemp).forEach(key => {
            if (['mark', 'model', 'version'].includes(key) || filterTemp[key] === '' || (new String(filterTemp[key])).match(/tod[ao]s/ig)) {
                return;
            }

            if ((key == 'value_min' || key == 'value_max' || key == 'km_min' || key == 'km_max') && filterTemp[key]) {
                filterTemp[key] = (new String(filterTemp[key])).replace('.', '')
            }

            if (key === 'optionals' && filterTemp[key].length) {
                query += `&${key}=${filterTemp[key]}`
            } else if (key !== 'optionals' && filterTemp[key] && filterTemp[key] !== '-') {
                query += `&${key}=${filterTemp[key]}`;
            }
        })

        const res = await axios.get(`${SEARCH_URI}${query.replace(/[\(\)\<\>\"\'\/\\]+/ig, '')}`);

        filters.page++;
        filters.total_pages = res.data.pager.total_pages;

        for (var x in res.data.response) {
            vehiclesList.push(res.data.response[x])
        }

        setVehiclesList(vehiclesList);
        setLoading(false);
    }

    useEffect(() => {
        const filterButton = document.querySelector('.search-filters-btn');
        const filterForm = document.querySelector('.search-filters-options');
        const filterClose = document.querySelector('.close-filters-options');

        if (filterButton) {
            filterButton.addEventListener('click', e => {
                e.preventDefault();
                filterForm.classList.toggle('active');
                filterButton.classList.toggle('active');
            });

            filterClose.addEventListener('click', e => {
                e.preventDefault();
                filterForm.classList.remove('active');
                filterButton.classList.remove('active');
            })
        }

        const scrollTop = function () {
            window.scrollTo(0, 0);
        };

        const cleanFilters = document.querySelector('.clean-filters');
        if (cleanFilters) {
            cleanFilters.addEventListener('click', e => {
                e.preventDefault();
                filters.mark = '';
                filters.mark_text = '';
                filters.model = '';
                filters.model_text = '';
                filters.version = '';
                filters.version_text = '';
                setFilters(filtersDefault);
                resetSearch();
                getSearch();
                scrollTop();
                window.location.reload();
            });
        }

        getSearch();
    }, [])

    const [options, setOptions] = useState({
        marks: [],
        models: [],
        versions: [],
        optionals: [],
    });

    useEffect(() => {
        (async function getDataAsync() {
            const getMarks = await axios.get(`${MARKS_URI}`);
            const getOptionals = await axios.get(`${OPTIONALS_URI}`);
            setOptions({
                ...options, ...{
                    marks: [{ id: '', label: 'Todas' }, ...getMarks.data?.response],
                    optionals: getOptionals.data?.response,
                }
            });
        })();
    }, []);

    function checkValues() {
        let campo, errors = {}, valuesMaxMin = config.totals || {}, error = false;
        if (filters.year_min) {
            let year_min = (filters.year_min).toString().replace(/\./g, "");
            if (year_min < valuesMaxMin?.ano_min) {
                errors.year_min = `Ano mínimo permitido é ${(valuesMaxMin?.ano_min)}`;
                campo = document.getElementById('year_min');
                campo.classList.add('danger-border');
                error = true;
            } else {
                errors.year_min = '';
            }
        }
        if (filters.year_max) {
            let year_max = (filters.year_max).toString().replace(/\./g, "");
            if (year_max > valuesMaxMin?.ano_max) {
                errors.year_max = `Ano máximo permitido é ${(valuesMaxMin?.ano_max)}`;
                campo = document.getElementById('year_max');
                campo.classList.add('danger-border');
                error = true;
            } else {
                errors.year_max = '';
            }
        }
        if (filters.value_min) {
            let value_min = (filters.value_min).toString().replace(/\./g, "");
            if (value_min < valuesMaxMin?.value_min) {
                errors.value_min = `Preço mínimo permitido é ${(valuesMaxMin?.value_min).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                campo = document.getElementById('value_min');
                campo.classList.add('danger-border');
                error = true;
            } else {
                errors.value_min = '';
            }
        }
        if (filters.value_max) {
            let value_max = (filters.value_max).toString().replace(/\./g, "");
            if (value_max > valuesMaxMin.value_max) {
                errors.value_max = `Preço máximo permitido é ${(valuesMaxMin.value_max).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                campo = document.getElementById('value_max');
                campo.classList.add('danger-border');
                error = true;
            } else {
                errors.value_max = '';
            }
        }
        if (filters.km_min) {
            let km_min = (filters.km_min).toString().replace(/\./g, "");
            if (km_min < valuesMaxMin?.km_min) {
                errors.km_min = `Quilometragem mínima permitido é ${(valuesMaxMin?.km_min)}`;
                campo = document.getElementById('km_min');
                campo.classList.add('danger-border');
                error = true;
            } else {
                errors.km_min = '';
            }
        }
        if (filters.km_max) {
            let km_max = (filters.km_max).toString().replace(/\./g, "");
            if (km_max > valuesMaxMin?.km_max) {
                errors.km_max = `Quilometragem máxima permitido é ${(valuesMaxMin?.km_max)}`;
                campo = document.getElementById('km_max');
                campo.classList.add('danger-border');
                error = true;
            } else {
                errors.km_max = '';
            }
        }

        setValidation({ ...validation, ...errors });

        return error
    }

    const setSearch = async (event) => {
        event.preventDefault();
        let error = checkValues();
        let filterTemp = {};
        Object.keys(filters).forEach(key => {
            if (filters[key]) filterTemp[key] = filters[key];
        })
        if (!error) {
            resetSearch();
            getSearch();

            //melhorar
            const filterButton = document.querySelector('.search-filters-btn');
            const filterForm = document.querySelector('.search-filters-options');

            filterForm.classList.remove('active');
            filterButton.classList.remove('active');
            //melhorar - fim
        }
    }

    return (
        <div className="search">
            <Header />
            <main>
                <div className="container mv-10">
                    <SearchComponent />
                </div>
                <div className="grid-search grid col-16" style={{ width: '100%', clean: 'both' }}>
                    <div className="span-3 search-filters sm-pd relative mobile-p0">
                        <div className="search-filters-options flex fd-fc">
                            <div className="group-filters div-car-brand">
                                <div className="mb-25 flex">
                                    <h2 className="fb-16 float-left">Marca</h2>
                                    <a href="#" className="close-filters-options i-16 ml-30 float-right">
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                    </a>
                                </div>
                                <div className="grid col-3 lt-gap ji-gc">
                                    <select name="mark" id="mark" className={'span-3 ' + (filters.mark === '' ? 'fake-placeholder' : '')}
                                        value={filters.mark}
                                        onChange={handleInputChange}>
                                        <option value="" disabled defaultValue hidden>Selecione a marca</option>
                                        {(options?.marks || []).map((option) => {
                                            return (<option key={option?.id || option} value={option?.id || option}>{option?.label || option}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="group-filters">
                                <h2 className="fb-16 mv-25">Modelo</h2>
                                <div className="grid sm-gap">
                                    <select name="model" id="model" disabled={options?.models.length == 0} className={filters.model === '' ? 'fake-placeholder' : ''}
                                        value={filters.model}
                                        onChange={handleInputChange}>
                                        <option value="" disabled defaultValue hidden>Selecione o modelo</option>
                                        {(options?.models || []).map((option) => {
                                            return (<option key={option?.id || option} value={option?.id || option}>{option?.label || option}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="group-filters">
                                <h2 className="fb-16 mv-25">Versão</h2>
                                <div className="grid sm-gap">
                                    <select name="version" id="version" disabled={options?.versions.length == 0} className={filters.version === '' ? 'fake-placeholder' : ''}
                                        value={filters.version}
                                        onChange={handleInputChange}>
                                        <option value="" disabled defaultValue hidden>Selecione a versão</option>
                                        {(options?.versions || []).map((option) => {
                                            return (<option key={option?.id || option} value={option?.id || option}>{option?.label || option}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="group-filters">
                                <h2 className="fb-16 mv-25">Faixa de Ano</h2>
                                <div className="grid col-2 sm-gap mb-5">
                                    <NumberFormat name="year_min" id="year_min" onChange={handleInputChange} onBlur={checkValues}
                                        value={filters.year_min} placeholder="de" />
                                    <NumberFormat name="year_max" id="year_max" onChange={handleInputChange} onBlur={checkValues}
                                        value={filters.year_max} placeholder="até" />
                                </div>
                                {validation.year_min ? <div className="fb-12 text-primary">* {validation.year_min}</div> : ''}
                                {validation.year_max ? <div className="fb-12 text-primary">* {validation.year_max}</div> : ''}
                            </div>

                            <div className="group-filters">
                                <h2 className="fb-16 mv-25">Faixa de Preço</h2>
                                <div className="grid col-2 sm-gap mb-5">
                                    <NumberFormat name="value_min" id="value_min" onChange={handleInputChange}
                                        value={filters.value_min} onBlur={checkValues}
                                        thousandSeparator="." decimalSeparator="," placeholder="de" />
                                    <NumberFormat name="value_max" id="value_max" onChange={handleInputChange}
                                        value={filters.value_max} onBlur={checkValues}
                                        thousandSeparator="." decimalSeparator="," placeholder="até" />
                                </div>
                                {validation.value_min ? <div className="fb-12 text-primary">* {validation.value_min}</div> : ''}
                                {validation.value_max ? <div className="fb-12 text-primary">* {validation.value_max}</div> : ''}
                            </div>

                            <div className="group-filters">
                                <h2 className="fb-16 mv-25">Faixa de Km</h2>
                                <div className="grid col-2 sm-gap mb-5">
                                    <NumberFormat name="km_min" id="km_min" onChange={handleInputChange}
                                        value={filters.km_min} onBlur={checkValues}
                                        thousandSeparator="." decimalSeparator="," placeholder="de" />
                                    <NumberFormat name="km_max" id="km_max" onChange={handleInputChange}
                                        value={filters.km_max} onBlur={checkValues}
                                        thousandSeparator="." decimalSeparator="," placeholder="até" />
                                </div>
                                {validation.km_min ? <div className="fb-12 text-primary">* {validation.km_min}</div> : ''}
                                {validation.km_max ? <div className="fb-12 text-primary">* {validation.km_max}</div> : ''}
                            </div>

                            {/* <div className="group-filters">
                    <h2 className="fb-16 mv-25">Vendedor</h2>
                    <div className="grid sm-gap">
                      <label className="group-checkbox">Concessionária
                        <input type="checkbox" id="vendedor_concessionaria" name="vendedor_concessionaria" checked={filters.vendedor_concessionaria} onChange={handleInputChange}/>
                        <span className="checkmark"/>
                      </label>
                      <label className="group-checkbox">Loja
                        <input type="checkbox" id="vendedor_loja" name="vendedor_loja" checked={filters.vendedor_loja} onChange={handleInputChange}/>
                        <span className="checkmark"/>
                      </label>
                    </div>
                  </div> */}

                            {/* <div className="group-filters">
                <h2 className="fb-16 mv-25">Opcionais</h2>
                <div className="grid sm-gap">
                  {(options?.optionals || []).map((option) => {
                    return (<label key={option.id} className="group-checkbox">{option.label}
                      <input type="checkbox" id={option.id} name="optionals" checked={(filters.optionals || []).includes(option.id)} onChange={handleInputChange}/>
                      <span className="checkmark"/>
                    </label>)
                  })}
                </div>
              </div> */}
                            <div className="grid sm-gap mt-20 mb-20">
                                <Button className="apply-filters btn btn-red fb-18 w-100 text-center h-black" text="Aplicar" onClickButton={(e) => setSearch(e)} />
                                <Button className="clean-filters btn bordered fb-18 w-100 text-center" text="Limpar filtros" />
                            </div>
                        </div>
                    </div>
                    <div className="span-13 search-results sm-pd">

                        <div className="flex desktop-none" style={{ width: '100%', clear: 'both', display: 'flex' }}>
                            <a href="#" className="search-filters-btn float-left bold flex mb-25">
                                <FontAwesomeIcon className="icon i-16 mr-10" icon={['fas', 'filter']} />
                                <span className="fb-20 desktop-none" style={{ display: 'initial' }}>Filtrar busca</span>
                            </a>
                        </div>

                        {(vehiclesList.length) ? (<SearchScroll items={vehiclesList} fetchMoreData={getSearch} hasMore={filters.page <= filters.total_pages} loader={loading} />) : (loading ? <span><b>Buscando...</b></span> : <span><b>Sem resultados.</b></span>)}

                    </div>
                </div>
            </main>
        </div>
    );
}

export default Search;
