
import './favorite.scss';
import Header from '../../components/Header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Card from "../../components/Card";
import Cookies from "js-cookie";
import config from "../../config/config";
import axios from "axios";
import { Helmet } from "react-helmet";

function Favorite() {
  const favorites = JSON.parse(Cookies.get('favorite') || '[]') || [];
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let list = [];
      const promises = favorites.map(async id => {
        const getVehicle = await axios.get(`${config.api}/vehicle/${id}?access_token=${config.access_token}`);
        list.push(getVehicle.data?.response)
      })
      await Promise.all(promises);
      setVehicles(list);
      window.scrollTo(0, 0);
      setLoading(false);
    }

    (async function getDataAsync() {
      await getData();
    })();
  },[])

  return loading ? <div className="loader loader-default is-active" /> : (
    <div className="favorite">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
        <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
        <meta name="author" content="Repass" />
        <title>Repass: Meus carros favoritos</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:url" content="https://repass.com.br/" />
        <meta property="og:site_name" content="Repass" />
        <meta property="og:see_also" content="https://repass.com.br/" />
        <meta property="og:image" content="https://repass.com.br/" />
        <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Header />
      <main>
        <section id="favorite-banner">
          <div className="container bg-pd">
            <h1 className="fb-59 text-center">Seus Carros Favoritos <FontAwesomeIcon className="heart ml-20" icon={['far', 'heart']} /></h1>
            <div className="grid col-5 sm-gap-30 mt-100">
              {(vehicles || []).map(v => {
                return <Card key={v.id} vehicle={v} canRemove={true} />
              })}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Favorite;
