
import './carValuation.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React, { useState, useEffect } from "react";
import Button from "../../components/Buttons";
import config from "../../config/config";
import axios from 'axios';
import { Alert } from 'reactstrap';
import { Helmet } from "react-helmet";

function CarValuation() {
  /* eslint-disable */
  const MARKS_URI = `${config.api}/marks?access_token=${config.access_token}`;
  const EXCHANGE_URI = `${config.api}/exchange?access_token=${config.access_token}`;
  const [etapa, setEtapa] = useState(1);
  // Form Content
  const [form, setForm] = useState({
    name: '',
    phone: '',
    mark: '',
    model: '',
    year_model: '',
    plate: '',
    km: ''
  });
  const [marks, setMarks] = useState([]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    target.classList.remove('danger-border');
    let isValid = true;
    if (['km', 'year_model'].includes(name)) {
      const numbers = /^[0-9]+$/;
      if (value && !value.match(numbers)) isValid = false;
    }
    if (isValid) setForm({ ...form, ...{ [name]: value } });
  }

  const checkMaskPhone = (event) => {
    const phone = document.getElementById('phone');
    event.target.classList.remove('danger-border')
    let valor = event.target.value;
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
    valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
    setForm({ ...form, ...{ phone: valor } });
  }

  const onBlurPhone = (event) => {
    if (form.phone.length < 15) {
      setForm({ ...form, ...{ phone: '' } });
    }
  }

  function checkValidation(dto) {
    let retorno = true, campo;
    for (let i in dto) {
      if (config.isEmptyNullOrUndefined(dto[i])) {
        campo = document.getElementById(i);
        campo.classList.add('danger-border');
        retorno = false;
      }
    }

    return retorno;
  }

  async function submitForm(event) {
    event.preventDefault();
    if (etapa === 1) {
      let isValid = checkValidation({ name: form.name, phone: form.phone });
      if (isValid) setEtapa(2);
    } else {
      let isValid = checkValidation({ mark: form.mark, model: form.model, year_model: form.year_model, plate: form.plate, km: form.km });
      if (isValid) {
        let body = new FormData();
        body.append('name', form.name);
        body.append('phone', (form.phone).replace(/\D/g, ""));
        const mark = marks.find(m => m.id === form.mark);
        body.append('mark', mark.label);
        body.append('model', form.model);
        body.append('year_model', form.year_model);
        body.append('plate', form.plate);
        body.append('km', form.km);
        let post = {
          method: 'post',
          url: `${EXCHANGE_URI}`,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: body
        }
        const res = await axios(post)
        if (res.data?.response?.status) {
          setshowAlert(true);
          initData();
        }
      }
    }
  }

  function initData() {
    const defaultForm = {
      name: '',
      phone: '',
      mark: '',
      model: '',
      year_model: '',
      plate: '',
      km: ''
    }
    setForm(defaultForm);
  }

  useEffect(() => {
    (async function getDataAsync() {
      const getMarks = await axios.get(`${MARKS_URI}`);
      setMarks(getMarks.data?.response);
    })();
  }, []);

  const [showAlert, setshowAlert] = useState(false);

  return (
    <div className="car-valuation">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
        <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
        <meta name="author" content="Repass" />
        <title>Repass: Quanto vale seu carro</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:url" content="https://repass.com.br/" />
        <meta property="og:site_name" content="Repass" />
        <meta property="og:see_also" content="https://repass.com.br/" />
        <meta property="og:image" content="https://repass.com.br/" />
        <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Header />
      <main>
        <section className="container small mb-40">
          <h1 className="title text-center mb-30">Quanto vale meu carro?</h1>
          <h2 className="fn-35 text-center mb-100">Avaliamos seu carro e agendamos uma visita.</h2>

          <form className="mb-100 mobile-form">
            <div className="grid col-2 id-gap">
              <div className="span-2 grid col-4 lt-gap">
                <a className={`button btn ${etapa === 1 ? 'btn-red h-white' : 'btn-white c-black'} fb-20 w-100 mw-100 as-gc`}> Sobre você </a>
                <a className={`button btn ${etapa === 2 ? 'btn-red h-white' : 'btn-white c-black'} fb-20 w-100 mw-100 as-gc`} onClick={submitForm}> Sobre o carro </a>
              </div>
              {etapa === 1 ? (<div className="span-2 grid col-2 id-gap">
                <input className="fn-20" type="text" placeholder="Nome completo*" name="name" id="name" required="" value={form.name} onChange={handleInputChange} />
                <input className="fn-20" type="phone" placeholder="Whatsapp*" name="phone" id="phone" required="" maxLength="15" pattern="\(\d{2}\)\s*\d{5}-\d{4}"
                  value={form.phone} onChange={checkMaskPhone} onKeyPress={checkMaskPhone} onBlur={onBlurPhone} />
                <div></div>
                <Button className="btn btn-red fb-20 w-100 mw-100 as-gc h-black" text="Avançar" onClickButton={submitForm} />
              </div>) : (<div className="span-2 grid col-2 id-gap">
                {/* <input className="fn-20" type="text" placeholder="Marca*"/> */}
                <select name="mark" id="mark" className="select-form-default fn-20" value={form.mark} onChange={handleInputChange}>
                  <option value="" disabled defaultValue hidden>Marca*</option>
                  {(marks || []).map((option) => {
                    return (<option key={option?.id || option} value={option?.id || option}>{option?.label || option}</option>)
                  })}
                </select>
                <input className="fn-20" type="text" placeholder="Modelo/Versão*" name="model" id="model" required="" value={form.model} onChange={handleInputChange} />
                <input className="fn-20" type="text" placeholder="Quilometragem*" name="km" id="km" required="" value={form.km} onChange={handleInputChange} />
                <input className="fn-20" type="text" placeholder="Placa*" name="plate" id="plate" required="" value={form.plate} onChange={handleInputChange} />
                <input className="fn-20" type="text" placeholder="Ano do modelo*" maxLength="4" name="year_model" id="year_model" required="" value={form.year_model} onChange={handleInputChange} />
                <Button className="btn btn-red fb-20 w-100 mw-100 as-gc h-black" text="Solicitar avaliação" onClickButton={submitForm} />
              </div>)}

              {(showAlert) ? (<Alert color="white">
                Mensagem enviada com sucesso!
              </Alert>
              ) : ''}

            </div>
          </form>

          <section className="grid col-3 id-gap mb-100 d-none">
            <div className="card">
              <h2 className="fb-25 mb-40">Lorem ipsum dolor sit amet</h2>
              <p className="fn-18">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut maximus risus vel mi lobortis accumsan
                  eget vitae eros. Donec dignissim cursus urna, eu volutpat metus consectetur in.</p>
            </div>
            <div className="card">
              <h2 className="fb-25 mb-40">Lorem ipsum dolor sit amet</h2>
              <p className="fn-18">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut maximus risus vel mi lobortis accumsan
                  eget vitae eros. Donec dignissim cursus urna, eu volutpat metus consectetur in.</p>
            </div>
            <div className="card">
              <h2 className="fb-25 mb-40">Lorem ipsum dolor sit amet</h2>
              <p className="fn-18">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut maximus risus vel mi lobortis accumsan
                  eget vitae eros. Donec dignissim cursus urna, eu volutpat metus consectetur in.</p>
            </div>
          </section>

          <p className="fn-20 d-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget nibh a velit ornare euismod.
          Aenean sem tellus, convallis ac ante in, vestibulum efficitur orci. Sed non erat lobortis magna
          lobortis luctus venenatis pellentesque tellus. Vestibulum condimentum, magna suscipit scelerisque
          egestas, turpis leo mollis nisi, quis cursus nisi nisl a metus. Curabitur sit amet aliquam orci.
          Nam hendrerit, felis sed convallis sagittis, nibh lectus iaculis justo, et dictum sapien mauris
              imperdiet ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          <p className="fn-20 d-none">Morbi pellentesque elit ac urna volutpat, at ultricies nunc fringilla. Donec ligula sem, lacinia
          vitae aliquet id, imperdiet nec ligula. Praesent luctus tellus molestie condimentum malesuada. Class
          aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi at libero
              tellus. In nulla risus, egestas in leo eu, semper pulvinar eros. Sed scelerisque interdum pharetra.</p>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default CarValuation;
