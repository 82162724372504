
import './needHelp.scss';
import '../../assets/css/accordion.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React, { Fragment, useState } from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { Helmet } from "react-helmet";

function NeedHelp() {
  let buy = [{
    question: 'O que são carros premium?',
    answer: 'São veículos exclusivos, comumente conhecidos como veículos de luxo.'
  },
  {
    question: 'Quais as vantagens de comprar pela Repass?',
    answer: 'Na Repass trabalhamos para facilitar sua vida, você compra carros sem burocracia, todos os' +
      'carros são inspecionados rigorosamente por especialistas, são entregues revisados, limpos e' +
      'higienizados. Possuímos também devolução garantida caso não fique satisfeito com o carro.'
  }];
  let sell = [{
    question: 'Quais as vantagens de vender pela Repass?',
    answer: 'Pagamos um preço justo pelo seu carro, sem burocracia e disponibilizamos seu carro para uma' +
      'grande quantidade de compradores em potencial.'
  },
  {
    question: 'A Repass me ajuda no processo de venda?',
    answer: 'Sim, a Repass conta com um time de especialista para te auxiliar em todo o processo de venda' +
      'do seu carro, estaremos ao seu lado do início ao fim.'
  }];

  const SEARCH_URI = 'https://api.rankim.com.br/suggestions?access_token=14235a62a41ea9e3880868d4cf63494488355e7b9607ad657693f33203bc4b20';

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSuggestions = async (query) => {
    setIsLoading(true);
    const res = await axios.get(`${SEARCH_URI}&text=${query}`);
    setOptions(res.data);
    setIsLoading(false);
  };

  const filterBy = () => true;

  return (
    <div className="need-help">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
        <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
        <meta name="author" content="Repass" />
        <title>Repass: Perguntas Frequentes</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:url" content="https://repass.com.br/" />
        <meta property="og:site_name" content="Repass" />
        <meta property="og:see_also" content="https://repass.com.br/" />
        <meta property="og:image" content="https://repass.com.br/" />
        <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Header />
      <section className="container large">
        <h1 className="title mb-110">Precisa de ajuda?</h1>
      </section>
      <form className="search-form container large mb-110">
        <AsyncTypeahead
          filterBy={filterBy}
          id="async-example"
          isLoading={isLoading}
          promptText="Digite para pesquisar"
          searchText="Procurando..."
          minLength={3}
          onSearch={handleSuggestions}
          options={options}
          emptyLabel="Nenhuma equivalência encontrada"
          placeholder="Busque sua dúvida"
          renderMenuItemChildren={(option, props) => (
            <Fragment>
              <span>{option}</span>
            </Fragment>
          )}
        />
        <FontAwesomeIcon className="icon" icon={['fas', 'search']} />
      </form>
      <section className="container large grid col-2 id-gap">
        <div className="question">
          <h1 className="mb-60">Compra</h1>
          <Accordion allowZeroExpanded>
            {buy.map((item, a) => (
              <AccordionItem key={a}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {item.question}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {item.answer}
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="question mb-110">
          <h1 className="mb-60">Venda</h1>
          <Accordion allowZeroExpanded>
            {sell.map((item, a) => (
              <AccordionItem key={a}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {item.question}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {item.answer}
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>

      </section>
      <Footer />
    </div>
  );
}

export default NeedHelp;
