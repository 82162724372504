import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import home from '../pages/home/home';
import vehicle from '../pages/vehicle/vehicle';
import search from '../pages/search/search';
import register from '../pages/register/register';
import error404 from '../pages/errors/404';
import privacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import termsUse from "../pages/termsUse/termsUse";
import carValuation from "../pages/carValuation/carValuation";
import needHelp from "../pages/needHelp/needHelp";
import advertise from "../pages/advertise/advertise";
import locationPage from "../pages/location/location";
import login from "../pages/login/login";
import favorite from "../pages/favorite/favorite";
import aboutUs from "../pages/aboutUs/aboutUs";
import searchSeller from '../pages/searchSeller/searchSeller';

function Routes() {

    if(window.location.pathname === '/anunciar'){
        document.body.classList.add("body-advertise");
    } else {
        document.body.classList.remove("body-advertis");
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={home} />
                <Route path="/login" exact component={login} />
                <Route path="/seus-carros-favoritos" exact component={favorite} />
                <Route path="/comprar" exact component={search} />
                <Route path="/anunciar" exact component={advertise} />
                <Route path="/politica-de-privacidade" exact component={privacyPolicy} />
                <Route path="/termos-de-uso" exact component={termsUse} />
                <Route path="/onde-estamos" exact component={locationPage} />
                <Route path="/quanto-vale-meu-carro" exact component={carValuation} />
                <Route path="/precisa-de-ajuda" exact component={needHelp} />
                <Route path="/sobre-nos" exact component={aboutUs} />
                <Route path="/comprar/:id" exact component={vehicle} />
                <Route path="/veiculo/:param1/:param2/:param3" exact component={vehicle} />
                <Route path="/criar-conta" exact component={register} />
                <Route path="/area-do-lojista" exact component={searchSeller} />
                <Route component={error404} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;