
import './vehicle.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from "react";
import axios from 'axios';
/* import { Alert } from 'reactstrap'; */

import ModalOffer from '../../components/ModalOffer';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import Button from "../../components/Buttons";
import NumberFormat from "react-number-format";
import config from "../../config/config";
import { Base64 } from "js-base64";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../../components/Card";
import DetailSwiper from "../../components/DetailSwiper";
import { Helmet } from "react-helmet";

SwiperCore.use([Navigation, Pagination, A11y]);

function Vehicle(param) {

  /* eslint-disable */
  let end = param.match.url.split('/').slice(-1)[0]
  let codigo = (end.replace('.html', '')).split('-').slice(-1)[0];
  const id = Base64.decode(codigo);

  const [loading, setLoading] = useState(true);
  // Form Content
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [vehicle, setVehicle] = useState({});
  const [opportunities, setOpportunities] = useState({});
  const VEHICLE_URI = `${config.api}/vehicle/${id}?access_token=${config.access_token}`;
  const OPPORTUNITIES_URI = `${config.api}/opportunities?access_token=${config.access_token}`;
  const LEAD_URI = `${config.api}/lead/${id}?access_token=${config.access_token}`;

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    target.classList.remove('danger-border');
    setForm({ ...form, ...{ [name]: value } });
  }

  const checkMaskPhone = (event) => {
    const phone = document.getElementById('phone');
    event.target.classList.remove('danger-border')
    let valor = event.target.value;
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
    valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
    setForm({ ...form, ...{ phone: valor } });
  }

  const onBlurPhone = (event) => {
    if (form.phone.length < 15) {
      setForm({ ...form, ...{ phone: '' } });
    }
  }

  useEffect(() => {
    const getData = async () => {
      const getVehicle = await axios.get(`${VEHICLE_URI}`);
      let vehicle = getVehicle.data?.response;
      let countLine = config.list(vehicle?.opcionais.length);
      vehicle.list = [[], [], [], []]
      vehicle?.opcionais.forEach((e, i) => {
        let index = (i / countLine) | 0;
        vehicle.list[index].push(e.descricao);
      });
      setVehicle(vehicle);
      const getOpportunities = await axios.get(`${OPPORTUNITIES_URI}`);
      setOpportunities(getOpportunities.data?.response);
      window.scrollTo(0, 0);
      setLoading(false);
    }

    (async function getDataAsync() {
      await getData();
    })();
  }, [VEHICLE_URI]);

  async function sendLead(event) {
    event.preventDefault();
    let isValid = checkValidation({ name: form.name, email: form.email, phone: form.phone, message: form.message });
    if (isValid) {
      let body = new FormData();
      body.append('name', form.name);
      body.append('email', form.email);
      body.append('phone', (form.phone).replace(/\D/g, ""));
      body.append('message', form.message);
      let post = {
        method: 'post',
        url: `${LEAD_URI}`,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: body
      }
      const res = await axios(post)
      if (res.data?.response?.status) {
        setshowAlert(true);
        initData();
      }
    }
  }

  function checkValidation(dto) {
    let retorno = true, campo;
    for (let i in dto) {
      if (config.isEmptyNullOrUndefined(dto[i])) {
        campo = document.getElementById(i);
        campo.classList.add('danger-border');
        retorno = false;
      }
    }

    return retorno;
  }

  function initData() {
    const defaultForm = {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
    setForm(defaultForm);
  }

  const [activeSlide, setActiveSlide] = useState();
  const getSlide = (e) => {
    e.preventDefault();
    if (Object.values(vehicle?.imagens).length) {
      let slide = e.target.ariaLabel.split(' / ')[0];
      setActiveSlide(slide - 1);
      openDetailSwiper(e);
    }
  }

  // ModalOffer
  const [showModalOffer, setShowModalOffer] = useState(false);
  const openModalModalOffer = (event) => {
    event.preventDefault();
    setShowModalOffer(!showModalOffer);
  }

  const [showDetailSwiper, setShowDetailSwiper] = useState(false);
  const openDetailSwiper = (event) => {
    event.preventDefault();
    setShowDetailSwiper(!showDetailSwiper);
  }
  // const parcelas = (event) => {
  //   event.preventDefault();
  //   let ancora = document.getElementById('parcelas');
  //   window.scrollTo(0, ancora?.offsetTop);
  // }

  // Favorite
  const [favorite, setFavorite] = useState({});
  const saveFavorite = (event, id) => {
    event.preventDefault();
    const isFavorite = config.addFavorite(id);
    addFavorite(isFavorite);
  }

  const addFavorite = (isFavorite) => {
    setFavorite({
      icon: isFavorite ? ['fas', 'heart'] : ['far', 'heart'],
      text: isFavorite ? 'Favorito' : 'Favoritar',
      favoriteClass: isFavorite ? 'favorite' : ''
    });
  }
  // Favorite
  useEffect(() => {
    // Set favorite
    const isFavorite = config.isFavorite(vehicle.id);
    addFavorite(isFavorite);
  }, [vehicle.id]);

  const [showAlert, setshowAlert] = useState(false);

  return loading ? <div className="loader loader-default is-active" /> : (
    <div className="vehicle">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
        <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
        <meta name="author" content="Repass" />
        <title>Repass: {vehicle?.marca + ' ' + vehicle?.modelo}</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:url" content="https://repass.com.br/" />
        <meta property="og:site_name" content="Repass" />
        <meta property="og:see_also" content="https://repass.com.br/" />
        <meta property="og:image" content="https://repass.com.br/" />
        <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <ModalOffer show={showModalOffer} hideModal={openModalModalOffer} title={vehicle.marca + ' ' + vehicle.modelo + ' ' + vehicle.titulo} />
      <DetailSwiper show={showDetailSwiper} hide={openDetailSwiper} activeSlide={activeSlide} imagens={vehicle?.imagens} />
      

      <Header />
      <main>
        <section className="vehicle-slider mt-20">
          <Swiper navigation slidesPerView="auto" grabCursor={true} effect="coverflow" spaceBetween={20}>
            {(Object.values(vehicle?.imagens || [])).map((images, i) => {
              return (<SwiperSlide onClick={getSlide} key={'img' + i} className="vehicle-image" style={{ backgroundImage: `url(${images})` }} />)
            })}
          </Swiper>
        </section>

        <section className="container-vehicle container large grid col-12 sm-gap">
          <section className="vehicle-div vehicle-description span-8">
            <h3 className="fb-30 flex ai-gc jc-sb">{vehicle?.marca + ' - ' + vehicle?.modelo}
              <FontAwesomeIcon className="heart" icon={favorite.icon} onClick={(evt) => saveFavorite(evt, vehicle.id)} />
            </h3>
            <h4 className="mb-60 f600-22 color-3" dangerouslySetInnerHTML={{ __html: vehicle?.titulo }} />
            <div className="grid col-4">
              <div className="span-3 grid col-3 sm-gap">
                <div className="mb-20">
                  <h5 className="f600-18 color-3 mb-20">Ano/Modelo</h5>
                  <h5 className="fb-18">{vehicle?.ano_fabricacao}/{vehicle?.ano_modelo}</h5>
                </div>
                <div className="mb-20">
                  <h5 className="f600-18 color-3 mb-20">Km</h5>
                  <NumberFormat className="fb-18" value={vehicle.km} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                </div>
                <div className="mb-20">
                  <h5 className="f600-18 color-3 mb-20">Câmbio</h5>
                  <h5 className="fb-18">{vehicle?.cambio}</h5>
                </div>
                <div className="mb-20">
                  <h5 className="f600-18 color-3 mb-20">Combustível</h5>
                  <h5 className="fb-18">{vehicle?.combustivel}</h5>
                </div>
                <div className="mb-20">
                  <h5 className="f600-18 color-3 mb-20">Portas</h5>
                  <h5 className="fb-18">{vehicle?.portas}</h5>
                </div>
                <div className="mb-20">
                  <h5 className="f600-18 color-3 mb-20">Cor</h5>
                  <h5 className="fb-18">{vehicle?.cor}</h5>
                </div>
              </div>
            </div>
            <h5 className="f600-18 color-3 mb-20">Sobre este carro</h5>
            <p className="fb-18">{vehicle?.descricao || '-'}</p>

            <hr className="hr-clear" />

            {!(vehicle.opcionais || []).length ? '' : (<section className="pt-20">
              <h5 className="f600-18 color-3 mb-20">Itens e acessórios</h5>
              <div className="grid col-4">
                {vehicle.list.map((it, i) => {
                  return (
                    <ul key={'item-' + i}>
                      {it.map((label, l) => {
                        return (
                          <li className="fb-18 pb-15 pr-15" key={'item-' + i + '-label-' + l}>{label}</li>
                        )
                      })}
                    </ul>
                  )
                })}
              </div>
            </section>
            )}

          </section>
          <section className="vehicle-div span-4 p-30">
            <div className="value grid col-5">
              <span className="span-5 block mb-15">
                {vehicle.valor >= 1000 ?
                  <small className="fn-33 mr-10">R$</small>
                  : ''
                }
                {vehicle.valor >= 1000 ?
                  <NumberFormat className="fb-49" value={vehicle.valor} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                  :
                  <div className="fb-49">Sob Consulta</div>
                }
              </span>
              <Button className="span-5 btn btn-green btn-h60 fb-20 as-gs mb-20 mt-10" text="Falar pelo Whatsapp" target="_blank" url={'https://api.whatsapp.com/send?phone=5547999465058&text=Ol%C3%A1%20Repass,%20tenho%20interesse%20em%20' + vehicle?.marca + ' ' + vehicle?.modelo + ' - ' + config.url + window.location.pathname} />
              {/* <Button className="span-5 btn btn-red btn-h60 fb-20 as-gs mb-20 mt-10" text="Fazer proposta" onClickButton={(evt) => openModalModalOffer(evt)} /> */}
            </div>
            {/* <form className="form mt-50">
              <h4 className="f600-22" style={{ textAlign: 'center' }}>Mensagem</h4>
              <div className="mt-0">
                <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">Nome:</label>
                <input type="text" name="name" id="name" required="" value={form.name} onChange={handleInputChange} />
              </div>
              <div className="mt-20">
                <label className="fn-14 mb-10 d-block ml-10" htmlFor="email">E-mail:</label>
                <input type="email" name="email" id="email" required="" value={form.email} onChange={handleInputChange} />
              </div>
              <div className="mt-20">
                <label className="fn-14 mb-10 d-block ml-10 mt-20" htmlFor="name">Telefone:</label>
                <input type="phone" name="phone" id="phone" required="" maxLength="15" pattern="\(\d{2}\)\s*\d{5}-\d{4}"
                  value={form.phone} onChange={checkMaskPhone} onKeyPress={checkMaskPhone} onBlur={onBlurPhone} />
              </div>
              <div className="mt-20">
                <label className="fn-14 mb-10 d-block ml-10 mt-20" htmlFor="message">Faça sua proposta:</label>
                <textarea rows="6" required="" name="message" id="message" value={form.message} onChange={handleInputChange} />
              </div>

              <Button className="btn w-100 btn-red btn-h60 fb-20 as-gs mt-30" text="Enviar Mensagem" onClickButton={sendLead} />

              {(showAlert) ? (<Alert color="white">
                Mensagem enviada com sucesso!
              </Alert>
              ) : ''}

            </form> */}
            <div className="mt-50" style={{ border: '1px solid #4caf50', borderRadius: '20px', padding: '20px' }}>
              <h4 className="f600-22" style={{ textAlign: 'center' }}>Reserve on-line</h4>
              <h5 className="fn-18 mt-20">1º Pague R$500 para reservar o veículo</h5>
              <h5 className="fn-18 color-3 mt-20">Você pode pagar antes ou depois de vê-lo.</h5>
              <h5 className="fn-18 mt-20">2º Combine o restante com o vendedor</h5>
              <h5 className="fn-18 color-3 mt-20">Coordene as condições do pagamento restante e a data de entrega do veículo.</h5>
              <Button className="span-5 btn btn-green btn-h60 fb-20 as-gs mt-20 w-100" style={{ color: 'green' }} text="Reservar" target="_blank" url={'https://api.whatsapp.com/send?phone=5547999465058&text=Ol%C3%A1%20Repass,%20quero%20reservar%20o%20veículo%20Cód.%20' + vehicle.id + ' - ' + vehicle?.marca + ' ' + vehicle?.modelo} />
              <h5 className="fn-14 mt-20">Sua reserva está protegida. Caso se arrependa ou tenha algum problema, devolvemos seu dinheiro.</h5>


            </div>
          </section>

          {/* {!(vehicle.opcionais || []).length ? '' : (<section className="vehicle-div vehicle-items span-8">
            <h5 className="f600-18 color-3 mb-40">Itens do Veículo</h5>
            <div className="grid col-4">
              {vehicle.list.map((it, i) => {
                return (
                  <ul key={'item-' + i}>
                    {it.map((label, l) => {
                      return (
                        <li className="fb-18 pb-15 pr-15" key={'item-' + i + '-label-' + l}>{label}</li>
                      )
                    })}
                  </ul>
                )
              })}
            </div>
          </section>
          )} */}

          {/*<section className="vehicle-div about-seller span-8 relative">*/}
          {/*  <h5 className="f600-18 color-3 mb-50">Sobre o Vendedor</h5>*/}
          {/*  <div className="grid col-2 id-gap">*/}
          {/*    <div className='seller flex ai-gc span-1'>*/}
          {/*      <img src={seller} alt=""/>*/}
          {/*      <div className="ml-50">*/}
          {/*        <h5 className="fb-18">Paulo Castro de Tarso</h5>*/}
          {/*        <p className="fn-16">Particular</p>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <img className="map span-1" src={map}  alt=""/>*/}
          {/*  </div>*/}
          {/*</section>*/}
          {/*<section className="vehicle-div price-comparison span-8">*/}
          {/*  <h5 className="f600-18 color-3 mb-20">Comparação de Preço</h5>*/}
          {/*  <div className="grid col-3 sm-gap">*/}
          {/*    <div>*/}
          {/*      <span className="block text-center mb-15">*/}
          {/*        <small className="fn-24 mr-10">R$</small>*/}
          {/*        <NumberFormat className="fb-40 green-color-2" value={vehicle.valor} displayType={'text'} thousandSeparator={'.'}  decimalSeparator={','} />*/}
          {/*      </span>*/}
          {/*      <p className="fn-16 text-center color-3">Valor Repass</p>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <span className="block text-center mb-15 grey-color">*/}
          {/*        <small className="fn-24 mr-10">R$</small>*/}
          {/*        <NumberFormat className="fb-40" value={258000} displayType={'text'} thousandSeparator={'.'}  decimalSeparator={','} />*/}
          {/*      </span>*/}
          {/*      <p className="fn-16 text-center color-3">Média do Valor na Internet</p>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <span className="block text-center mb-15 grey-color">*/}
          {/*        <small className="fn-24 mr-10">R$</small>*/}
          {/*        <NumberFormat className="fb-40" value={267000} displayType={'text'} thousandSeparator={'.'}  decimalSeparator={','} />*/}
          {/*      </span>*/}
          {/*      <p className="fn-16 text-center color-3">Tabela Fipe</p>*/}
          {/*    </div>*/}
          {/*    */}
          {/*  </div>*/}
          {/*</section>*/}
          {/*<div className="span-12 grid col-12 d-none">*/}
          {/*  <section className="vehicle-div finance span-8">*/}
          {/*    <h3 className="fb-30">Quer financiar este carro?</h3>*/}
          {/*    <h5 className="f600-18 color-3 mb-20">Para ver as parcelas, precisamos de alguns dados :)</h5>*/}
          {/*    <div className="grid col-2 id-gap form mb-60">*/}
          {/*      <div className="mt-20">*/}
          {/*        <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">Nome</label>*/}
          {/*        <input type="text" name="name" id="name" required="" />*/}
          {/*      </div>*/}
          {/*      <div className="mt-20">*/}
          {/*        <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">Data de Nascimento</label>*/}
          {/*        <input type="text" name="name" id="name" required="" />*/}
          {/*      </div>*/}
          {/*      <div className="mt-20" id="parcelas">*/}
          {/*        <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">E-mail</label>*/}
          {/*        <input type="text" name="name" id="name" required="" />*/}
          {/*      </div>*/}
          {/*      <div className="mt-20">*/}
          {/*        <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">CPF</label>*/}
          {/*        <input type="text" name="name" id="name" required="" />*/}
          {/*      </div>*/}
          {/*      <div className="mt-20">*/}
          {/*        <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">Telefone</label>*/}
          {/*        <input type="text" name="name" id="name" required="" />*/}
          {/*      </div>*/}
          {/*      <div className="mt-20">*/}
          {/*        <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">Localização</label>*/}
          {/*        <input type="text" name="name" id="name" required="" />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="grid col-6 id-gap value">*/}
          {/*      <div className="span-4">*/}
          {/*        <label className="flex ai-fc normal">*/}
          {/*          <input type="checkbox" name="apartamento" />*/}
          {/*          <span className="block mid-text ml-10 w-90">Quero receber ofertas da Webmotors e da Santander Financiamentos*/}
          {/*          via e-mail, whatsapp e demais canais.</span>*/}
          {/*        </label>*/}
          {/*      </div>*/}
          {/*      <Button className="span-2 btn btn-red btn-h60 fb-20 as-gs" text="Ver condições" />*/}

          {/*    </div>*/}
          {/*  </section>*/}
          {/*</div>*/}
          <div className="span-12" />
          <section className="span-3 p-30">
            <h1 className="fb-36">Veja o que separamos para você.</h1>
            <FontAwesomeIcon className="arrow arrow-right" icon={['fas', 'arrow-right']} />
            <FontAwesomeIcon className="arrow arrow-down" icon={['fas', 'arrow-down']} />
          </section>
          <section className="span-9 p-30 grid">
            <section className="suggestion-slider mt-20">
              <Swiper navigation slidesPerView="auto" grabCursor={true} effect="coverflow"
                spaceBetween={30}>
                {opportunities.map(vehicle => {
                  return (<SwiperSlide key={vehicle.id} className="suggestion-image">
                    <Card vehicle={vehicle} />
                  </SwiperSlide>)
                })}
              </Swiper>
            </section>
          </section>

        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Vehicle;
