
import './location.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function location() {
    return (
        <div className="location">
          <Header />
          <section className="container large">
            <h1 className="title mb-30">Onde estamos?</h1>
          </section>
          <section className="container-location grid col-3 id-gap mb-15">
            <div className="card-location">
              <h4 className="fb-22 mb-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
              <p className="w-80 fn-18 mb-50">Donec iaculis augue turpis, sit amet vestibulum turpis maximus eu.</p>
              <span className="block fn-22 w-100 mb-50">
                <FontAwesomeIcon className="icon mr-20" icon={['fas', 'phone-alt']} /> 47 3034 9648
              </span>
              <span className="block fn-22 w-100">
                <FontAwesomeIcon className="icon mr-20" icon={['fas', 'envelope']} /> mail@repass.com.br
              </span>
            </div>
            <div className="card-location">
              <h4 className="fb-22 mb-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
              <p className="w-70 fn-18 mb-50">Donec iaculis augue turpis, sit amet vestibulum turpis maximus eu.</p>
              <span className="block fn-22 w-100 mb-50">
                <FontAwesomeIcon className="icon mr-20" icon={['fas', 'phone-alt']} /> 47 3034 9648
              </span>
              <span className="block fn-22 w-100">
                <FontAwesomeIcon className="icon mr-20" icon={['fas', 'envelope']} /> mail@repass.com.br
              </span>
            </div>
            <div className="card-location">
              <h4 className="fb-22 mb-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
              <p className="w-70 fn-18 mb-50">Donec iaculis augue turpis, sit amet vestibulum turpis maximus eu.</p>
              <span className="block fn-22 w-100 mb-50">
                <FontAwesomeIcon className="icon mr-20" icon={['fas', 'phone-alt']} /> 47 3034 9648
              </span>
              <span className="block fn-22 w-100">
                <FontAwesomeIcon className="icon mr-20" icon={['fas', 'envelope']} /> mail@repass.com.br
              </span>
            </div>
          </section>
          <Footer />
        </div>
    );
}

export default location;
