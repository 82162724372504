import Cookies from 'js-cookie'

const config = {
    name: 'Repass',
    url: 'https://repass.com.br',
    release: '1.0.1',
    api: 'https://api.repass.com.br',
    access_token: '2b3babdc4ea7271fde9ed5d1142f14b15615e376fcd0b9707f467c2d24861612',
    keyGoogleMaps: 'AIzaSyAz9AAJp-YnIUaqXp_6a_dRAWDsOiO0sIk',
    socialNetworks: {
        facebook: 'https://www.facebook.com/repassofficial',
        instagram: 'https://www.instagram.com/repassoficial',
        twitter: 'https://twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        youtube: 'https://www.youtube.com/',
    },
    nav : [
        {
            title: 'início',
            url: '/'
        },
        {
            title: 'quem somos',
            url: '/sobre-nos'
        },
        {
            title: 'comprar',
            url: '/comprar'
        },
        {
            title: 'vender',
            url: '/quanto-vale-meu-carro'
        },
        {
            title: 'entrar',
            url: 'https://app.repass.com.br/',
            target: '_blank'
        },
    ],
    
    totals: null,
    
    //
    list: (qtd = 0) => {
        let retorno = 5;
        let qtdDefault = 20;
        for (let i = 0; i < 10; i++) {
            if (qtd <= qtdDefault) {
                break;
            } else {
                qtdDefault += 4;
                retorno++;
            }
        }
        return retorno
    },
    
    isBase64: (str) => {
        try {
            return btoa(atob(str)).replace('=', '') === str.replace('=', '');
        } catch (err) {
            return false;
        }
    },

    isEmptyNullOrUndefined: (string) => {
        return !string || string === null || string === undefined || string === 0 || string === "" || string === "null";
    },
    
    addFavorite: (id) => {
        if(id){
            const list = JSON.parse(Cookies.get('favorite') || '[]') || [];
            let newList = list;
            let isNewId = true;
            if(list.includes(id)){
                newList = list.filter((i) => {
                    return i !== id
                });
                isNewId = false;
            } else {
                newList.push(id);
            }
            console.log(newList)
            Cookies.set('favorite', JSON.stringify(newList), { expires: 2147483647, path: '/' });
            return isNewId
        }
    },
    
    isFavorite: (id) => {
        const list = JSON.parse(Cookies.get('favorite') || '[]') || [];
        return list.includes(id);
    }
}

export default config;