import React from 'react';
import '../assets/css/footer.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../config/config";

function Footer() {
  return (
    <footer>
      <div className="footer container large">
        <div className="grid col-2">
          <div>
            <h3>Fale por Whatsapp</h3>
            <h2 className="mt-20"><a className="whats-link" href="https://api.whatsapp.com/send?phone=5547999465058&text=Olá%20Repass,%20poderia%20me%20ajudar?" target="_blank" rel="noreferrer">(47) 9 9946-5058</a></h2>
            <p className="mt-30">A Repass nasceu para revolucionar o modo com que você compra e vende veículos premium.</p>

            <div className="social-media flex lt-gap mt-40">
              <a className="circle mr-10" href={config.socialNetworks.facebook} target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon" icon={['fab', 'facebook-f']} />
              </a>
              <a className="circle mr-10" href={config.socialNetworks.instagram} target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon" icon={['fab', 'instagram']} />
              </a>
            </div>
          </div>
        </div>
        <div className="grid sm-gap col-2 mt-60">
          <div className="div-privacity text-center"><a className="h-black" href="/politica-privacidade">Política de Privacidade</a></div>
          <div className="div-privacity text-center">Launched by <a className="h-black" href="https://phpenterprise.com/" rel="noreferrer" target="_blank">PHP Enterprise </a>
            <FontAwesomeIcon className="icon ml-5" icon={['fas', 'rocket']} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;