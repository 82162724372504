
import './advertise.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
//import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Buttons";
import Search from "../../components/Search";
import { Helmet } from "react-helmet";

function Advertise() {

  /* const [dto, setDto] = useState({
     want_receive_offers: false
   });*/

  /*const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setDto({ ...dto, ...{ [name]: value } });
  }*/

  return (
    <div className="advertise">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,veiculos,carros,compra,venda,anuncio,premium,exclusivos" />
        <meta name="description" content="Plataforma de compra, venda e anúncios de veículos premium e exclusivos" />
        <meta name="author" content="Repass" />
        <title>Repass: Anuncie seu carro</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:description" content="Compra e venda de veículos premium e exclusivos" />
        <meta property="og:url" content="https://repass.com.br/" />
        <meta property="og:site_name" content="Repass" />
        <meta property="og:see_also" content="https://repass.com.br/" />
        <meta property="og:image" content="https://repass.com.br/" />
        <meta property="og:image:url" itemprop="image" content="https://repass.com.br/" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Header />
      <main className="mt-20">
        <section id="advertise">
          <div className="container large mb-90">
            <Search />
            <h1 className="advertise-title mb-30 fb-49">Anuncie <br /> seu carro na Repass, <br /> e desapegue.</h1>
            <div className="form-email grid col-3 sm-gap">
              {/* <div className="span-2">
                      <input className="input-email fn-16" type="email" placeholder="E-mail"/>
                      <FontAwesomeIcon className="icon" icon={['fas', 'search']} />
                    </div> */}
              <Button className="btn btn-red fb-18 w-100 mw-100 as-gc h-black" text="Quero anúnciar" url="/quanto-vale-meu-carro" />

              <div className="div-checkbox span-3">
                {/* <input type="checkbox" id="want_receive_offers" name="want_receive_offers" checked={dto.want_receive_offers} onChange={handleInputChange}/>
                      <label className="fn-16" htmlFor="want_receive_offers">
                        Quero receber ofertas, descontos, lançamentos e informações
                      </label> */}

                {/* <label className="group-checkbox">Quero receber ofertas, descontos, lançamentos e informações
                        <input type="checkbox" id="want_receive_offers" name="want_receive_offers" checked={dto.want_receive_offers} onChange={handleInputChange}/>
                        <span className="checkmark"/>
                      </label> */}
              </div>
            </div>

            <div className="ads grid col-10 md-gap">
              <div className="span-4">
                <h1 className="f600-49 mb-70">Sabemos quanto vale o seu carro!</h1>
                <div className="fn-18">
                  Nosso time de especialistas ajudará você em todo esse processo, assim conseguirá vender seu
                  veículo facilmente.
                </div>
              </div>

              <div className="js-fc mt-30 span-3 text-center ph-10" >
                <h3 className="fb-30">Ao vender pela Repass</h3>
                <div className="sell-in">
                  <span className="currency-br fb-30">R$</span>
                  <span className="fb-73">130.650</span>
                </div>
                <div className="tag-fipe tag-green">
                  Próximo a FIPE
                </div>
                {/*<div className="fn-18 ph-50">*/}
                {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris accumsan risus id leo tincidunt volutpat.*/}
                {/*</div>*/}
              </div>

              <div className="js-fc mt-30 span-3 text-center ph-10">
                <h3 className="fb-30">Ao vender fora da Repass</h3>
                <div className="sell-out">
                  <span className="currency-br fb-30">R$</span>
                  <span className="fb-73">112.200</span>
                </div>
                <div className="tag-fipe tag-red">
                  - 25% da FIPE
                </div>
                {/*<div className="fn-18 ph-50">*/}
                {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris accumsan risus id leo tincidunt volutpat.*/}
                {/*</div>*/}
              </div>
            </div>

          </div>
        </section>

        <section id="advantages">
          <div className="container large mb-100">
            <h1 className="fb-49 text-center">Se liga nas vantagens</h1>
            <div className="advantages-grid grid col-4 bg-gap">
              <div className="advantages-card">
                <div className="adv-circle">
                  <FontAwesomeIcon className="icon" icon={['fas', 'check']} />
                </div>
                <p className="fn-22 text-center">Preço justo</p>
              </div>
              <div className="advantages-card">
                <div className="adv-circle">
                  <FontAwesomeIcon className="icon" icon={['fas', 'check']} />
                </div>
                <p className="fn-22 text-center">Venda rápida</p>
              </div>
              <div className="advantages-card">
                <div className="adv-circle">
                  <FontAwesomeIcon className="icon" icon={['fas', 'check']} />
                </div>
                <p className="fn-22 text-center">Baixa burocracia</p>
              </div>
              <div className="advantages-card">
                <div className="adv-circle">
                  <FontAwesomeIcon className="icon" icon={['fas', 'check']} />
                </div>
                <p className="fn-22 text-center">Assessoria especializada</p>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="sell-it">
          <div className="sell-grid grid col-2 ai-gc sm-gap m-auto">
            <h1 className="fb-34 w-100">Pronto para vender seu carro?</h1>
            <Button className="sell-btn btn btn-white fb-23" text="Quero Anunciar" url="/quanto-vale-meu-carro" />
          </div>
        </section> */}
      </main>
      <Footer />
    </div>
  );
}

export default Advertise;
