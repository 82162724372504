
import './login.scss';
import Header from '../../components/Header';
import Button from "../../components/Buttons";
import React from "react";

function login() {
    return (
        <div className="login">
            <Header/>
            <main>
                <section id="login-banner">
                    <div className="container bg-pd">
                        <div className="grid col-2 sm-gap">
                            <div />
                            <div>
                                <h1 className="fb-79 mb-20">Entrar</h1>
                                <p className="fn-18 mb-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <form className="form">
                                    <div className="mt-30">
                                        <label className="fn-14 mb-10 d-block ml-10" htmlFor="name">E-mail</label>
                                        <input type="text" name="name" id="name" required="" />
                                    </div>
                                    <div className="mt-30">
                                        <label className="fn-14 mb-10 d-block ml-10 mt-30" htmlFor="name">Senha</label>
                                        <input type="password" name="name" id="name" required="" />
                                    </div>
                                </form>
    
                                <div className="grid grid-btn col-2 sm-gap mt-100">
                                    <Button className="btn btn-bg btn-account btn-h64 fb-20" text="Criar Conta" />
                                    <Button className="btn btn-bg btn-red btn-h64 fb-20 as-gs" text="Entrar" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="images"/>
                </section>
            </main>
        </div>
    );
}

export default login;
