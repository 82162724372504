
import './register.css';

function register() {
    return (
        <div className="register">
            register
        </div>
    );
}

export default register;
